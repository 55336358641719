import {Box, Button, CircularProgress, styled, TablePagination} from "@mui/material";

import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import SurveySlackCardItem from "./survey-card-item";
import SurveyTableSearch from "../survey-table-search";
import {useTranslation} from "react-i18next";
import Lottie from 'react-lottie'
import animationData from '../stars.json'
import {usePostAiIntegrationsGuard} from "../../../../guards/post-ai-integrations-guard";
import EditPanel from "../EditPanel";
import PostShow from "../post-show/post-show";
import {connect} from "react-redux";
import {getActiveSchedule, nextPublishDate} from "../../../../helpers/region";
import * as moment from "moment";

const CustomScrollContent = styled('div')(({theme}) => ({
    overflowX: "auto",
    [theme.breakpoints.up('xs')]: {
        maxWidth: "calc(100% - 5px)",
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: "calc(100% - 55px)",
    }

}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, filter) {
    array = array.filter((el) => {
        const include = el.title.toLowerCase().includes(filter.toLowerCase());
        return filter === '' || include;
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const SurveySlackCard = (props) => {
    const {status, surveys} = props;
    const [order, setOrder] = useState('desc');
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState('updateAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
    const [editData, setEditData] = useState(0);
    const [postaiIntegration, setPostaiIntegration] = useState(usePostAiIntegrationsGuard());


    const {t} = useTranslation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeFilter = (value) => {
        setFilter(value);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        },
      }

    const handleOpenEditPanel = (data) => {
        setEditData(data);
        setIsEditPanelOpen(true);
    }

    const handleCloseEditPanel = () => {
        setIsEditPanelOpen(false);
    }

    function groupByDay(data) {
        console.log("data", data)
        return data.reduce((acc, item) => {
            const schedule = getActiveSchedule(item["analytics_post_ai_schedules"])
            const dateKey = nextPublishDate(schedule)
            // const dateKey = item.postDate.split(' ')[0];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, []);
    }

    return (
        <>
            {!props.singlePostai ? <Box sx={{width: '100%'}}>
            <div className="py-3">
                {postaiIntegration && <NavLink to='/chat-agpost' style={{textDecoration: 'none'}}>
                    <Button variant="contained">{t('New post')}</Button>
                </NavLink>}
            </div>
            <div className="my-3">
                {/*<SurveyTableSearch setFilter={handleChangeFilter}/>*/}
            <div className="row" style={{paddingInline:'30px'}}>
                {status === 'loading' ?
                    <div className="text-center col-12">
                        <Lottie options={defaultOptions} height={40} width={40}/>
                    </div>
                    :
                    <>
                        {/*stableSort(surveys, getComparator(order, orderBy), filter)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                        {Object.entries(groupByDay(surveys)).map(([date, items], index) =>
                            <div className="row pt-4">
                                <div className="col-lg-12">
                                    {moment(date).isValid() ? <h3>
                                        <span className="text-secondary">{moment(date).format('D MMM') || ''}</span><b>{moment(date).format(' dddd')}</b>
                                    </h3> : <h3><b className="text-info">{date}</b></h3>}
                                </div>
                                <CustomScrollContent className="col-lg-12">
                                    <div className="row my-1 flex-nowrap">
                                        {items.map(item =>
                                            <div className="col-11 col-sm-8 col-md-4 col-lg-3">
                                                <SurveySlackCardItem key={index} item={item} onClickEdit={handleOpenEditPanel}/>
                                            </div>)}
                                    </div>
                                </CustomScrollContent>
                            </div>

                        )}
                    </>
                }
            </div>
                {/*<TablePagination
                className="child-mb-0"
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={surveys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>*/}
            </div>
            {/*<div className="py-3">
                <NavLink to='/survey-create' style={{textDecoration: 'none'}}>
                    <Button variant="contained">+ New Survey</Button>
                </NavLink>
            </div>*/}
            {
                isEditPanelOpen &&
                <EditPanel data={editData} onClickClose={handleCloseEditPanel}/>
            }
        </Box> :
                <PostShow/>}
        </>
    );
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        singlePostai: state.postais.singlePostai,
        status: state.postais.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySlackCard);
