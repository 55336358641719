import { connect } from "react-redux";
import { Badge, Chip, CircularProgress, Tooltip } from "@mui/material";
import logo from '../../assets/Instagram_icon.png';
import { toastr } from "react-redux-toastr";
import { useEffect } from "react";
import { disconnectInstagramIntegrationService, setNewInstagramIntegrationService } from "../../services/agent-post-ai.service";
import {
    deleteDriveUserThunk,
    getUserWithEmailThunk,
    updateDriveUserThunk,
    updateIndexUserThunk
} from "../../redux/users/users.thunk";
import {useState} from "react";

const CustomChip = (props) => {
    return (
        <Chip
            variant="filled"
            disabled={props.status === 'loading'}
            avatar={
                props.status === 'loading' ? (
                    <CircularProgress size={20} />
                ) : (
                    <img style={{ width: '35px', height: '35px' }} src={logo} alt="Instagram Logo" />
                )
            }
            label="Instagram"
            // component="a"
            // href="https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1212429533294440&redirect_uri=https://app.mymoodbit.com/&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish"
            {...props}
        />
    );
}

const InstagramButton = (props) => {
    const [status, setStatus] = useState("initial")


    const existInstagramAccount = () => {
        return props.user.analytics_instagram_integrations !== null && props.user.analytics_instagram_integrations?.length > 0;
    }

    const disconnect = async () => {
        if (window.confirm(`Are you sure you want to disconnect your Instagram account?`)) {
            setStatus("loading")
            await disconnectInstagramIntegrationService(props.user.id);
            await props.getUser(props.user.email);
            setStatus("loaded")
        }
    }

    const handleButtonClick = () => {
        const popup = window.open(`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1212429533294440&redirect_uri=${process.env.REACT_APP_IG_REDIRECT_URL}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`, 'popup', 'width=600,height=600');
        console.log("Popup opened");

        const handleMessage = async (event) => {
            console.log("Received event:", event);
            
            const { accessToken, userId} = event.data;

            console.log("received data from ig: ", accessToken, userId)
            try {
                const response = await setNewInstagramIntegrationService({
                    accessToken: accessToken,
                    userId: userId,
                    analyticsUserId: props.user.id 
                });
                console.log("RESPONSE INTEGRATION: ", accessToken, userId, props.user.id );
                console.log("RESPONSE INTEGRATION: ", response);
                props.getUser(props.user.email);

                toastr.success("Connected!", "You have successfully logged in to your Instagram account");
                alert("Instagram integration successful");
            } catch (error) {
                console.error("Failed to set Instagram integration:", error);
                toastr.error("Failed to set Instagram integration");
                alert("Failed to set Instagram integration");
            } finally {
                window.removeEventListener('message', handleMessage);
                if (popup) {
                    popup.close();
                }
            }
        };

        window.addEventListener('message', handleMessage);
    };

    return (
        <Tooltip
            title={existInstagramAccount() ? props.user.analytics_linkedin_integrations[0]["username"] : ''}
            className="w-100"
        >
            <Badge color="success" variant="dot" invisible={!existInstagramAccount()}>
                {existInstagramAccount() ? (
                    <CustomChip
                        label={<small className="text-black-50">Connected</small>}
                        className="py-4 w-100 justify-content-center"
                        onClick={() => {}}
                        onDelete={() => disconnect()}
                        status={status}
                    />
                ) : (
                    <CustomChip
                        className="py-4 w-100"
                        onClick={handleButtonClick}
                        status={status}
                    />
                )}
            </Badge>
        </Tooltip>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstagramButton);
