import * as moment from "moment";

export const isCountries = (country) => {
    /// TODO change for a list of countries blank labeled
    // return country === 'Bolivia';
    return false;
}

export function nextPublishDate(schedule) {
    if (schedule) {

        const createDate = schedule.createdAt
        const dayWeek = schedule.days.findIndex(value => value === true);
        const hour = schedule.hours;
        const minute = schedule.minutes;

        const date = moment(createDate);
        const dayWeekCreation = date.weekday();

        if (dayWeek >= dayWeekCreation) {
            date.weekday(dayWeek);
        } else {
            date.add(1, 'week').weekday(dayWeek);
        }
        date.set({
            hour: hour,
            minute: minute,
            second: 0,
            millisecond: 0
        });

        return date.format('YYYY-MM-DD HH:mm:ss');
        // return date.format('ddd, D MMM YYYY')
    } else {
        return "Posts without scheduled time"
    }
}


export const getActiveSchedule = (schedules) => {
    console.log('schedules:', schedules)
    if (schedules.length > 0) {
        for (let schedule of schedules) {
            if (schedule.state === true) {
                return schedule;
            }
        }
    }
    return false;
}
