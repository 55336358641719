import {useEffect, useState} from "react";
import * as moment from 'moment';
import PostMonthItem from "./post-month-item";
import PostShow from "../post-show/post-show";
import {connect} from "react-redux";
import {getAllMonthPostService} from "../../../../services/agent-post-ai.service";
import {CircularProgress, styled} from "@mui/material";
import {useParams} from "react-router-dom";
import { toastr } from "react-redux-toastr";

const CustomScrollContent = styled('div')(({theme}) => ({
    overflowX: "auto",
    [theme.breakpoints.up('xs')]: {
        maxWidth: "calc(100% - 5px)",
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: "calc(100% - 55px)",
    }

}));

const PostMonthShow = (props) => {
    const [posts, setPosts] = useState(null)
    const [post, setPost] = useState(null)
    const [loading, setLoading] = useState(false)
    const {plan_id} = useParams();
    useEffect(() => {
        // console.log("postTestData", postTestData)
        const func = (async () => {
            try {
                setLoading(true)
                const posts = await getAllMonthPostService({
                    // analytics_user_id: props.user.id
                    plan_id
                })
                setLoading(false)
                console.log("POSTS MONTH: ", posts)

                const newPosts = groupByDay(posts)
                setPosts(newPosts);
            } catch (e) {
                setLoading(false)
                console.log("ERROR get posts", e)
                toastr.error(`Something went wrong, please reload the page to try again`);
            }
        })

        func();

    }, [post])

    function groupByDay(data) {
        console.log("data", data)
        return data.reduce((acc, item) => {
            const dateKey = item.postDate.split(' ')[0];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, {});
    }

    return (
        <div className="container-fluid mt-2">
            <div className="w-100">
                {!post ? <div className="content-month" style={{
                        height: "87vh",
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>

                        {loading && <div className="d-flex justify-content-center">
                            <CircularProgress/>
                        </div>}
                        {posts && Object.entries(posts).map(([date, items]) => (
                            <div className="row pt-4">
                                <div className="col-lg-12"><h3><span
                                    className="text-secondary">{moment(date).format('D MMM')}</span><b>{moment(date).format(' dddd')}</b>
                                </h3></div>
                                <CustomScrollContent className="col-lg-12">
                                    <div className="row my-1 flex-nowrap">
                                        {items.map(item =>
                                            <div className="col-11 col-sm-8 col-md-4 col-lg-3">
                                                <PostMonthItem item={item} setPost={setPost}/>
                                            </div>)}
                                    </div>
                                </CustomScrollContent>
                            </div>))}

                    </div> :
                    <PostShow post={post} setPost={setPost}/>}
                {posts && Object.entries(posts).length === 0 &&
                    <h4 className="text-secondary">You don't have any plans yet</h4>}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PostMonthShow);
