import {useNavigate} from "react-router-dom";
import PostMonthListIndex from "./post-month-list/post-month-list-index";
import {usePostAiIntegrationsGuard} from "../../../guards/post-ai-integrations-guard";

const AgentPostMonthIndex = () => {
    const navigate = useNavigate()
    return (
        <div className="container-fluid mt-2">
            <div className="">
                {usePostAiIntegrationsGuard() && <button className="btn btn-primary" onClick={() => navigate('/new-post-month')}>
                    NEW
                </button>}

                {/*usePostAiIntegrationsGuard() && <button className="ms-2 btn btn-primary" onClick={() => navigate('/show-post-month')}>
                    SEE ALL
                </button>*/}
            </div>
            <div className="">
                <PostMonthListIndex/>
            </div>
        </div>
    )
}

export default AgentPostMonthIndex;
